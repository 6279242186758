import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bf624a46 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _7ad107a0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _3c6083da = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _6db6c14a = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _3f2e7aca = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _7cd6e140 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _1cdd0912 = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _289e84e7 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _06150c43 = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _6857ecae = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _a9113dac = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _b35ed5ac = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _76caeea2 = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _65f416a3 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _fb63f974 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _4d407d49 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _08df0d66 = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _a3a7ad3a = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _79160052 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _dbb2de86 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _4537428c = () => interopDefault(import('../pages/checkout/transsmart.vue' /* webpackChunkName: "pages/checkout/transsmart" */))
const _2e921433 = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _da09e508 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _e0da756e = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _28daad10 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _7e3e94f9 = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _6adcd7dc = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4c5b267a = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _256b3a84 = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _27602efc = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _4934e1fc = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _d755d116 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _660de9e2 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _081bd2a6 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _7e901649 = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _758b8f28 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _4cf86c8a = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _0c649193 = () => interopDefault(import('../pages/review/_slug.vue' /* webpackChunkName: "pages/review/_slug" */))
const _00965be3 = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _fd88ae8c = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _6e83f5cb = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _76b3c2fd = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _a1a787ba = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _6ef2e502 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _379f8be2 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _bf624a46,
    children: [{
      path: "",
      component: _7ad107a0,
      name: "account___nl"
    }, {
      path: "details",
      component: _3c6083da,
      name: "account-details___nl"
    }, {
      path: "newsletter",
      component: _6db6c14a,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _3f2e7aca,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _7cd6e140,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _1cdd0912,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _289e84e7,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _06150c43,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _6857ecae,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _a9113dac,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _b35ed5ac,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _76caeea2,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/cart",
    component: _65f416a3,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _fb63f974,
    children: [{
      path: "",
      component: _4d407d49,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _08df0d66,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _a3a7ad3a,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _79160052,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _dbb2de86,
      name: "checkout-payment___nl"
    }, {
      path: "transsmart",
      component: _4537428c,
      name: "checkout-transsmart___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _2e921433,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _da09e508,
    name: "checkout-success___nl"
  }, {
    path: "/faq",
    component: _e0da756e,
    name: "faq___nl"
  }, {
    path: "/forgot-password",
    component: _28daad10,
    name: "forgot-password___nl"
  }, {
    path: "/icon",
    component: _7e3e94f9,
    name: "icon___nl"
  }, {
    path: "/login",
    component: _6adcd7dc,
    name: "login___nl"
  }, {
    path: "/merken",
    component: _4c5b267a,
    name: "brand___nl"
  }, {
    path: "/nieuws",
    component: _256b3a84,
    name: "blog-category___nl"
  }, {
    path: "/vestigingen",
    component: _27602efc,
    name: "store-lister___nl"
  }, {
    path: "/blocks/demo",
    component: _4934e1fc,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _d755d116,
    name: "forgot-password-reset___nl"
  }, {
    path: "/login/guest",
    component: _660de9e2,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _081bd2a6,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/nieuws/detail/:slug?",
    component: _7e901649,
    name: "blog-detail-slug___nl"
  }, {
    path: "/product/detail/:slug?",
    component: _758b8f28,
    name: "product-detail-slug___nl"
  }, {
    path: "/product/lister/*",
    component: _4cf86c8a,
    name: "product-lister-all___nl"
  }, {
    path: "/review/:slug?",
    component: _0c649193,
    name: "review-slug___nl"
  }, {
    path: "/search/:slug?",
    component: _00965be3,
    name: "search-slug___nl"
  }, {
    path: "/winkel/:slug?",
    component: _fd88ae8c,
    name: "store-detail-slug___nl"
  }, {
    path: "/nieuws/*",
    component: _6e83f5cb,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _76b3c2fd,
    name: "blog-tag-all___nl"
  }, {
    path: "/merken/*",
    component: _a1a787ba,
    name: "brand-lister-all___nl"
  }, {
    path: "/",
    component: _6ef2e502,
    name: "index___nl"
  }, {
    path: "/*",
    component: _379f8be2,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
